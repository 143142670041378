
function preprocessingCheckboxes(checkboxes) {
  if (!checkboxes) return [];
  if (checkboxes[0].type) return checkboxes;

  const checkboxesRez = JSON.parse(JSON.stringify(checkboxes)).map(item => {
    return {
      ...item,
      type: "standart"
    }
  });
  
  const htmlString = checkboxesRez[0].html;
  const startIndex = htmlString.indexOf("Совершая");
  const endingString = "Согласие на обработку персональных данных</a>";
  const endIndex = htmlString.indexOf(endingString);
  const extractedString = `${htmlString}`.substring(startIndex, endIndex + endingString.length);
  checkboxesRez[0].html = checkboxesRez[0].html.replace(extractedString + ",", "Вы ознакомились и даете");

  checkboxesRez.unshift({
    html: extractedString,
    checked: false,
    type: "showcase"
  });

  return checkboxesRez;
}

function checkWhereCanGo(checkboxes, checked) {
  if (!checked || !checkboxes) return "";

  let result = "";
  let countStandart = checkboxes.filter(item => item.type === "standart").length;
  checked.forEach(element => {
    if(element.includes("showcase")){
      result = "showcase";
    }

    if(element.includes("standart")){
      countStandart--;
    }
  });

  if(!countStandart){
    result = "next";
  }
  
  return result;
}

export {
  preprocessingCheckboxes,
  checkWhereCanGo
}